import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from 'layouts/zh'
import SEO from 'components/seo'
import SectionContent from 'components/SectionContent'

// import classnames from 'classnames'
import * as styles from './index.module.less'

const About: React.FC = () => {
  return (
    <Layout>
      <AboutState />
    </Layout>
  )
}

const AboutState: React.FC = () => {
  const { aboutYaml } = useStaticQuery(graphql`
    query {
      aboutYaml {
        title
        desc
        keywords
        section1 {
          title
          dataList
        }
        section2 {
          title
          dataList
        }
        section3 {
          title
          dataList
        }
        section4 {
          title
          dataList
        }
        section5 {
          title
          dataList
        }
        section6 {
          title
          dataList
        }
      }
    }
  `)
  const { title, desc, keywords, section1, section2, section3, section4, section5, section6 } = aboutYaml
  return (
    <>
      <SEO title={title} description={desc} keywords={keywords} />
      <main className="lg:mt-[70px] mt-[4rem]">
        <SectionContent title={section1?.title}>
          <div className={styles.Content}>
            {section1?.dataList?.map((item) => {
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
        <SectionContent title={section2?.title}>
          <div className={styles.Content}>
            {section2?.dataList?.map((item) => {
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
        <SectionContent title={section3?.title}>
          <div className={styles.Content}>
            {section3?.dataList?.map((item) => {
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
        <SectionContent title={section4?.title}>
          <div className={styles.Content}>
            {section4?.dataList?.map((item) => {
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
        <SectionContent title={section5?.title}>
          <div className={styles.Content}>
            {section5?.dataList?.map((item) => {
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
        <SectionContent title={section6?.title}>
          <div className={styles.Content}>
            {section6?.dataList?.map((item) => {
              return <p key={item}>{item}</p>
            })}
          </div>
        </SectionContent>
      </main>
    </>
  )
}

export default About
